import React from 'react';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import quotes from '../images/quote mark.svg';
import logo from '../images/Logo.svg';

const Home = (props) => {
  return (
    <Layout>
      <SEO title="Welcome" />
      <Helmet>
        <meta
          name="Edduus"
          content="An intelligent communities of learning platform, that is social by design, delivers learning in micro bites and enables people to gain knowledge on the things that matter to them."
        />
      </Helmet>
      <section className="secondheader">
        <div className="container secondheader-content">
		    <div className="secondheader-content-logo">
		      <img alt="Logo" src={logo} />
		    </div>
		    <div className="secondheader-content-text">
		      <p><b>An intelligent communities of learning platform, that is social by design, delivers learning in micro bites and enables people to gain knowledge on the things that matter to them.</b></p>
		    </div>
        </div>  
      </section>
      <section className="edduus-info" id="edduus-info">
        <div className="edduus-info-content">
          <div>
            <p className="edduus-info-content-title">WHAT IS EDDUUS?</p>
          </div>
          <p className="edduus-info-content-header">The communities of learning platform</p>
          <p className="edduus-info-content-text">Everyone in the world has something they need to know more about, and now Edduus has created a learning application designed just for you. We call it social learning because it matches your learning needs with your interests and social connections.
It does not matter what you need to learn, we make learning about the things you need to learn, authentic and personalised.</p>
        </div>
        <div className="edduus-info-footer">
          <img alt="Quotes" src={quotes} />
          <p className="edduus-info-footer-text">Edduus is the platform that everyone can use to learn and socialize in an intuitive and adaptive way</p>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
